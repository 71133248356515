@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

.full-screen-image-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../img/lake.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-container-final {
  position: absolute;
  top: 20px;
  left: 20px;
}

.centered-text {
  font-size: 3em;
  font-weight: 300;
  color: #2e3a4c;
  font-family: "Oswald", sans-serif;
}

button {
  background: none;
  border: none;
  color: white; /* Ajusta el color según tu preferencia */
  cursor: pointer;
  margin-right: 10px; /* Espacio entre botones */
}
