body {
  color: #2a2a2a;
}

.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  backdrop-filter: blur(10px);
}

/* Glassmorphism effect card */
.signup-card {
  padding: 40px;
  background: rgba(255, 255, 255, 0.2); /* Keep the glass effect */
  backdrop-filter: blur(15px);
  border-radius: 10px;

  text-align: center;
  width: 300px;
}

/* Other styles remain unchanged */

input {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.social-signin {
  margin-top: 20px;
}

.google-btn {
  background-color: #db4437;
  color: white;
}

/* Full-screen container with glass effect */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-size: cover; /* Cover the entire screen */
  background-position: center; /* Center the background image */
  backdrop-filter: blur(10px); /* Apply the glass effect */
  position: fixed; /* Ensure it covers the whole screen */
  top: 0;
  left: 0;
}

/* Glassmorphism effect card */
.signup-card {
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.line {
  flex: 1;
  height: 1px;
  border-top: 1px solid rgba(129, 134, 142, 0.5);
}

.text {
  padding: 0 10px;
  font-size: 14px;
}

.signup-container {
  position: relative;
  min-height: 100vh; /* Ensure container takes up full viewport height */
}

.icon-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

.terms-bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}

.tiene-cuenta {
  font-size: 13px;
  font-weight: 300;
}

.link-tiene-notiene {
  color: #0e9272;
  text-decoration: none;
}
