body {
  color: #2a2a2a;
}

/* Full-width elements like RadioGroup or single text fields */
reg2 > .MuiFormControl-root,
reg2 > .MuiTextField-root {
  grid-column: span 2;
}

/* Specific styles for text fields */
.MuiTextField-root {
  background: white;
}

/* Style for the dropdown selectors */
.MuiFormControl-root {
  background: white;
}

/* Radio buttons alignment */
.MuiRadioGroup-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.MuiFormControlLabel-root {
  margin-right: 30px; /* Spacing between radio options */
}

/* Style for radio buttons */
.MuiRadio-root {
  color: #000; /* Default color, can be changed as needed */
}

/* Style for the buttons */
.MuiButton-root {
  background: #10a37f; /* Example color, change as needed */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
}

.MuiButton-root:hover {
  background: #0e9272; /* Darker shade for hover effect */
}

/* Style for the bottom terms of use */
.terms-bottom {
  text-align: center;
  padding-top: 20px;
}

/* Adjusts label color for select dropdown */
.MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.54);
}

/* Sets the color for the select dropdown */
.MuiSelect-select {
  color: #000;
}

/* Place first name and last name side by side */

.grid-item-first-name {
  grid-column: span 1;
}

.grid-item-last-name {
  grid-column: span 1;
}

/* Responsive design: At smaller screen widths, stack the inputs */
@media (max-width: 600px) {
  .grid-item-first-name,
  .grid-item-last-name {
    grid-column: span 2;
  }
}

/* Ensure that these classes are targeting the correct form elements */
.grid-item-document-type,
.grid-item-document-number {
  grid-column: span 1;
}

/* Ensure there's no unwanted margin or padding */
.MuiFormControl-root {
  margin: 0;
  padding: 0;
}

/* Place first name and last name side by side */
.grid-item-first-name,
.grid-item-last-name {
  grid-column: span 1;
}

/* Set reasonable horizontal gap between first name and last name */
.grid-item-first-name {
  margin-right: 10px;
}

/* Ensure there's no unwanted margin or padding */
.MuiFormControl-root {
  margin: 0;
  padding: 0;
}

/* Ensure there's reasonable spacing between document type and number */
.grid-item-document-number {
  margin-top: 10px;
}

.grid-container {
  display: flex;
  align-items: center; /* Vertically align the items */
}

.grid-item {
  flex: 1; /* Each item takes up equal space */
}

.gutter {
  width: 20px; /* Adjust the width of the gutter gap */
}

.titulo-continua {
  width: 100%;
}

.icon-top1 {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-top1 > * {
  margin-right: 10px; /* Agrega un pequeño espacio entre los elementos */
}

.signup-container1 {
  width: 700px;
  margin: 0 auto; /* Center the container */
}

/* Responsive adjustment for smaller screens */
@media (max-width: 768px) {
  .signup-container1 {
    width: 95%;
  }
}
