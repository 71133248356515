@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  height: 100vh;
  width: 100%;
}

.column {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column; /* Change the flex direction to column */
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: black;
  font-size: 20px;
  font-family: "Oswald", sans-serif;
}

.leftColumn {
  background-color: transparent;
}

.rightColumn {
  background-color: transparent;
}

.main-title {
  font-size: 100px;
  color: #2a2a2a;
  font-weight: 900;
  margin: 0;
  padding: 10px;
}

.bote {
  width: 80%;
  height: auto;
}

@media (max-width: 666px) {
  .container {
    flex-direction: column;
  }
}

.buttons-container {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 20px; /* Optional: adds space between the buttons */
}
